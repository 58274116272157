import { UPDATE_NLP_MODEL_STATE, MT_LOAD_DEPLOYMENT_LOGS } from "./actiontypes"
import * as API from "./api"
import { message } from "antd"

import { fetchDataAndProceed } from "../../utils/dataUtility"
import { METHOD_TYPES } from "../../constants/common"
import { LAMBDA_URL } from "../../config/urls"

export const updateNlpModelState = payload => {
  return {
    type: UPDATE_NLP_MODEL_STATE,
    payload,
  }
}

//Model Table
export const getModelsData = (data, tableLoader, errorCb) => dispatch => {
  dispatch(
    updateNlpModelState({ mt_loading: tableLoader, mt_load_error: false })
  )
  fetchDataAndProceed(
    {
      url: API.getModels,
      data,
      loader: false,
      axios_args: {
        baseURL: LAMBDA_URL,
      },
    },
    (error, response) => {
      if (!error && response && response.data) {
        dispatch(
          updateNlpModelState({
            models: response.data.models || [],
            mt_total: response.data.totalCount || 0,
            mt_loading: false,
            mt_load_error: false,
            mt_inprogress_env: response.data.inProgressEnv,
          })
        )
      }
      else {
        dispatch(updateNlpModelState({
          models: [],
          mt_inprogress_env: [],
          mt_loading: false,
          mt_load_error: true
        }))
        if(errorCb) errorCb()
      }
    }
  )
}

//Model Graph
export const getModelsGraph = data => dispatch => {
  dispatch(updateNlpModelState({ mg_loading: true, mg_load_error: false }))
  fetchDataAndProceed(
    {
      url: API.modelAccuracyGraph,
      data,
      loader: false,
    },
    (error, response) => {
      if (!error && response && response.data) {
        dispatch(
          updateNlpModelState({
            modelGraph: response.data.modelGraph || [],
            mg_loading: false,
            mg_load_error: false,
          })
        )
      } else {
        dispatch(
          updateNlpModelState({
            modelGraph: [],
            mg_loading: false,
            mg_load_error: true,
          })
        )
      }
    }
  )
}

//Delete a Model
export const deleteModel = (data, callback) => dispatch => {
  fetchDataAndProceed(
    {
      url: API.deleteModel,
      method: METHOD_TYPES.DELETE,
      data,
      loader_text: "Deleting",
      axios_args: {
        baseURL: LAMBDA_URL,
      },
    },
    (error, response) => {
      if (!error && response) {
        if (callback) callback()
        message.success(
          response.message ? response.message : "Model deleted successfully!"
        )
      }
    }
  )
}

//Deploy a model
export const deployModel = (data, callback) => dispatch => {
  fetchDataAndProceed(
    {
      url: API.deployModel,
      method: METHOD_TYPES.POST,
      data,
      loader: false,
      axios_args: {
        baseURL: LAMBDA_URL,
      },
    },
    (error, response) => {
      if (!error && response) {
        if (callback) callback()
        message.success(
          response.message ? response.message : "Model has been deployed"
        )
      }
    }
  )
}

// upload a file
export const nlpFileUpload = (data, callback) => dispatch => {
  fetchDataAndProceed(
    {
      url: API.nlpFileUpload,
      method: METHOD_TYPES.POST,
      data,
      modalLoader: true,
      content_type: "multipart/form-data",
    },
    (error, response) => {
      if (!error && response)
        dispatch(
          updateNlpModelState({ accuracy: response.data.accuracy || null })
        )
      if (callback) callback()
      message.success(
        response.data.message
          ? response.data.message
          : "File uploaded successfully."
      )
    }
  )
}

export const getDeploymentLogs = data => dispatch => {
  fetchDataAndProceed(
    {
      url: API.getDeploymentLogs,
      data,
      loader: false,
      axios_args: {
        baseURL: LAMBDA_URL,
      },
    },
    (err, res) => {
      if (!err && res && res.data && res.data.DeploymentLogs) {
        dispatch({
          type: MT_LOAD_DEPLOYMENT_LOGS,
          payload: res.data,
        })
      }
    }
  )
}
