import {
  UPDATE_REPORTS_STATE,
  REPORT_DETAILS_LOADED,
  RC_LOAD_MORE,
  RC_CREATED,
  RC_DELETED,
  RC_UPDATED,
} from "./actiontypes"
import states from "./states"
import { MODULE_CONFIG } from "../../config/setup"

const reports_details = (state = states.reports_details, action) => {
  switch (action.type) {
    case UPDATE_REPORTS_STATE:
      return {
        ...state,
        ...action.payload,
      }

    case REPORT_DETAILS_LOADED:
      return {
        ...state,
        selected_report: {
          ...state.selected_report,
          report_item: action.meta.report
            ? action.meta.report
            : state.selected_report.report_item,
          filters: action.payload.filters
            ? action.payload.filters
            : state.selected_report.filters,
          table_columns: action.payload.tableColumns
            ? action.payload.tableColumns.map(col => ({
                className: "ori-min-width-150",
                ...col,
              }))
            : state.selected_report.table_columns,
          table_data: action.payload.tableData,
          has_more_pages:
            action.payload.tableData &&
            action.payload.tableData.length ===
              MODULE_CONFIG.CONSOLE.REPORTS_PER_PAGE_TABLE_DATA,
          current_page:
            action.meta.data.page && action.meta.data.page.currentPageNumber
              ? action.meta.data.page.currentPageNumber
              : 1,
          lastScannedId: action.payload.lastScannedId || "",
          firstScannedId: action.payload.firstScannedId || "",
        },
        selected_filters: action.meta.data.initialFetch
          ? states.reports_details.selected_filters
          : action.meta.data.selectedFilters,
        selected_worksheet_details: {
          worksheet_id: action.meta.data.worksheetId,
          report_id: action.meta.report
            ? action.meta.report._id
            : state.selected_report.report_item._id,
        },
      }

    case RC_LOAD_MORE:
      return {
        ...state,
        report_configurations: [
          ...state.report_configurations,
          ...action.payload,
        ],
        rc_has_more:
          action.payload.length ===
          MODULE_CONFIG.CONSOLE.REPORTS_CONFIGURATION_PER_PAGE_TABLE_DATA,
      }
    case RC_CREATED:
      return {
        ...state,
        report_configurations: [action.payload, ...state.report_configurations],
      }

    case RC_DELETED: {
      let index = -1
      index = state.report_configurations.findIndex(
        report_configurations => report_configurations.id === action.payload
      )
      if (index !== -1)
        return {
          ...state,
          report_configurations: [
            ...state.report_configurations.slice(0, index),
            ...state.report_configurations.slice(index + 1),
          ],
        }
      return state
    }

    case RC_UPDATED: {
      let index = -1
      index = state.report_configurations.findIndex(
        report_configurations =>
          action.id === report_configurations.id
      )
      if (index !== -1)
        return {
          ...state,
          report_configurations: [
            ...state.report_configurations.slice(0, index),
            {
              ...state.report_configurations[index],
              ...action.payload,
            },
            ...state.report_configurations.slice(index + 1),
          ],
        }
      return state
    }

    default:
      return state
  }
}

export default reports_details
