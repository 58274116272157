export const SOCKET_EVENTS = {
  //-------------------- common events --------------------

  CONNECT: "connect",
  CONNECT_ERROR: "connect_error",
  CONNECT_TIMEOUT: "connect_timeout",
  ERROR: "error",
  RECONNECT: "reconnect",
  RECONNECT_ERROR: "reconnect_error",
  RECONNECTING: "reconnecting",
  DISCONNECT: "disconnect",
  TRACKER: "tracker",

  //-------------------- chats events --------------------

  ADDITION: "addition",
  DELETION: "deletion",
  UPDATION: "updation",
  JOIN: "join",
  LEAVE: "leave",
  TAG_ADDITION: "tagAddition",
  JOIN_UNTAGGED_CHATS: "joinUntaggedChats",
  CHAT_ACCEPTED: "chat_accepted",
  CHAT_REJECTED: "chat_rejected",
  JOIN_ROOM: "joinRoom",
  SUPERVISE: "supervise",
  STOP_SUPERVISING: "stopSupervising",
  RESOLVE_CHAT: "resolve_chat",
  ADMIN_TO_USER_MSG: "adminToUserMsg",
  WHISPER: "whisper",
  RESPONSE: "response",
  USER_MESSAGE: "userMessage",
  JOIN_AGENT_TRANSFER: "joinAgentTransfer",
  TRANSFER_AVAILABILITY_LIST: "transferAvailabilityList",
  LEAVE_AGENT_TRANSFER: "leaveAgentTransfer",
  TRANSFER_CHAT: "transfer_chat",
  AVAILABILITY_UPDATE: "availabilityUpdate",
  GET_AGENT_STATE: "getAgentStates",
  CHANGE_AGENT_STATE: "changeAgentState",
  TYPING_STATUS: "typingStatus",
  UPDATE_CUSTOMER_MESSAGE: "updateCustomerMessage",
  UPDATE_MESSAGE: "updateMessage",
  MESSAGE_SEEN: "messageSeen",
  FORCE_TAKEOVER: "forceTakeOver",
  ADD_TAG_TO_CHAT: "addTagToChat",
  REMOVE_TAG_FROM_CHAT: "removeTagFromChat",
  ESCALATE: "escalate",
  BLOCK_REQUEST: "block",
  BLANK: "blank",
  HOLD: "hold",
  DOWNTIME: "downTime",
  FORCE_LOGOUT: "forceLogout",
  PENDING_CHAT_TIMEOUT: "pendingChatTimeout",

  TAKEOVER: "takeover",
  RELINQUISH: "relinquish",

  //-------------------- agent monitoring events --------------------

  JOIN_GROUP_LIVE_STATS: "joinGroupLiveStats",
  LEAVE_GROUP_LIVE_STATS: "leaveGroupLiveStats",
  GROUP_LIVE_STATS: "groupLiveStats",
  BRAND_LIVE_STATS: "brandLiveStats",

  JOIN_AGENT_LIVE_STATS: "joinAgentLiveStats",
  LEAVE_AGENT_LIVE_STATS: "leaveAgentLiveStats",
  AGENT_LIVE_STATS: "agentLiveStats",

  GET_AGENT_CHATS: "getAgentChats",

  //-------------------- dashboard events --------------------

  JOIN_LIVE_STATS: "joinLiveStats",
  LEAVE_LIVE_STATS: "leaveLiveStats",
  PER_SECOND_STATS: "perSecondStats",
  PER_MINUTE_STATS: "perMinuteStats",
  LAST_N_HOURS_TIME_SERIES: "lastNHoursTimeSeries",

  //--------------------- usages events ----------------------

  DETAILED_CHAT_STATS: "detailedChatStats",

  //-------------------- dialogue events --------------------
  JOIN_DIALOGUE: "joinDialog",
  NEW_DIALOGUE_MESSAGE: "newDialogueMessage",
  UPDATE_DIALOGUE_NAME: "updateDialogueName",
  DELETE_DIALOGUE_BOX: "deleteDialogueBox",
  GET_DIALOGUE_BOX_DATA: "getDialogueBoxData",
  GET_FILTERED_INTENTS: "getFilteredIntents",
  GET_FILTERED_ACTIONS: "getFilteredActions",
  SAVE_DIALOGUE: "saveDialogue",
  CANCEL_DIALOGUE: "cancelDialogue",
  SELECTION: "selection",

  //-------------------- ticket events --------------------
  JOIN_TICKET: "joinTicket"
}
