import { combineReducers } from "redux"

import getInitialStates from "./initial_states"

import { clearAllDataFromLocalStorage } from "../utils/dataUtility"
import { RESET_REDUX_STATE } from "./admin_details/actiontypes"

import page_details from "./page_details/reducers"
import admin_details from "./admin_details/reducers"
import dashboard_details from "./dashboard_details/reducers"
import chats_details from "./chats_details/reducers"
import agent_monitoring_details from "./agent_monitoring_details/reducers"
import brand_functions_details from "./brand_function_details/reducers"
import canned_response_details from "./canned_response_details/reducers"
import intent_details from "./intent_details/reducers"
import event_details from "./event_details/reducers"
import entity_details from "./entity_details/reducers"
import workflow_details from "./workflow_details/reducers"
import message_details from "./message_details/reducers"
import botparams_details from "./botparams_details/reducers"
import downtime_details from "./downtime_details/reducers"
import agent_details from "./agent_details/reducers"
import group_details from "./group_details/reducers"
import usage_details from "./usage_details/reducers"
import team_member_setting_details from "./team_member_setting_details/reducers"
import session_flow_details from "./session_flow_details/reducers"
import feedback_details from "./feedback_details/reducers"
import kpi_details from "./kpi_details/reducers"
import conversion_details from "./conversion_details/reducers"
import fracture_details from "./fracture_details/reducers"
import nlp_details from "./nlp_details/reducers"
import history_details from "./history_details/reducers"
import reports_details from "./reports_details/reducers"
import tags_details from "./tags_details/reducer"
import chatlog_details from "./chatlog_details/reducers"
import customers_details from "./customers_details/reducers"
import query_details from "./query_details/reducers"
import apilogs_details from "./apilogs_details/reducers"
import template_master_details from "./template_master_details/reducers"
import broadcast_history_details from "./broadcast_history_details/reducers"
import nlp_model_details from "./nlp_model_details/reducers"
import dialogue_details from "./dialogue_details/reducers"
import domain_details from "./domain_details/reducers"
import action_details from "./action_details/reducers"
import environment_variables_details from "./environment_variables_details/reducers"
import ml_configuration_details from "./ml_configuration_details/reducers"
import configuration_details from "./configuration_details/reducers"
import testcases_details from "./testcases_details/reducers"
import testruns_details from "./testruns_details/reducers"
import catalogue_details from "./catalogue_details/reducers"
import faqbuilder_details from "./faqbuilder_details/reducers"
import training_logs_details from "./training_logs_details/reducers"
import tickets_details from "./tickets_details/reducer"
import chat_analytics_details from "./chat_analytics_details/reducers"
import workflow_tree_details from "./workflow_tree_details/reducers"

const appReducer = combineReducers({
  page_details,
  admin_details,
  dashboard_details,
  chats_details,
  agent_monitoring_details,
  brand_functions_details,
  canned_response_details,
  intent_details,
  event_details,
  entity_details,
  workflow_details,
  message_details,
  botparams_details,
  downtime_details,
  agent_details,
  group_details,
  usage_details,
  team_member_setting_details,
  session_flow_details,
  feedback_details,
  kpi_details,
  conversion_details,
  fracture_details,
  nlp_details,
  history_details,
  reports_details,
  tags_details,
  chatlog_details,
  customers_details,
  query_details,
  apilogs_details,
  template_master_details,
  broadcast_history_details,
  nlp_model_details,
  dialogue_details,
  domain_details,
  action_details,
  environment_variables_details,
  ml_configuration_details,
  configuration_details,
  testcases_details,
  testruns_details,
  catalogue_details,
  training_logs_details,
  faqbuilder_details,
  tickets_details,
  chat_analytics_details,
  workflow_tree_details
})

const rootReducer = (state, action) => {
  if (action.type === RESET_REDUX_STATE) {
    clearAllDataFromLocalStorage()
    state = getInitialStates()
  }
  return appReducer(state, action)
}

export default rootReducer
