import { message } from "antd"

import * as API from "./api"
import { METHOD_TYPES } from "../../constants/common"
import { fetchDataAndProceed } from "../../utils/dataUtility"
import {
  DL_UPDATE_STATE,
  DL_MAKE_SOCKET_CONNECTION,
  DL_CALL_SOCKET_METHOD,
  DL_SOCKET_DISCONNECT,
  DL_EMIT_CUSTOM_EVENT,
  DL_DELETE_MESSAGE,
} from "./actiontypes"

//================================== COMMON ====================================

export const updateDialogueState = payload => ({
  type: DL_UPDATE_STATE,
  payload,
})

//================================== DIALOGUES ====================================

export const getDialogueRecords = (data, callback) => dispatch => {
  dispatch(updateDialogueState({ loading: true, load_error: false }))
  fetchDataAndProceed(
    {
      url: API.getDialogueRecords,
      data,
      loader: false,
    },
    (error, response) => {
      if (!error && response) {
        dispatch(
          updateDialogueState({
            records: response.data.dialogues,
            total_records: response.data.totalCount || 0,
            loading: false,
            load_error: false,
          })
        )
        if (callback) callback()
      } else {
        dispatch(updateDialogueState({ loading: false, load_error: true }))
      }
    }
  )
}

export const deleteDialogue = (data, callback) => dispatch => {
  dispatch(updateDialogueState({ loading: true, load_error: false }))
  fetchDataAndProceed(
    {
      url: API.deleteDialogue,
      method: METHOD_TYPES.POST,
      data,
      loader: false,
    },
    (error, response) => {
      if (!error && response) {
        if (callback) callback()
        dispatch(
          updateDialogueState({
            loading: false,
            load_error: false,
          })
        )
        message.success(
          response.message ? response.message : "Successfully deleted dialogue!"
        )
      } else {
        dispatch(updateDialogueState({ loading: false }))
      }
    }
  )
}

export const updateDialogueStatus = (data, callback) => dispatch => {
  dispatch(updateDialogueState({ loading: true, load_error: false }))
  fetchDataAndProceed(
    {
      url: API.updateDialogueStatus,
      method: METHOD_TYPES.POST,
      data,
      loader: false,
    },
    (error, response) => {
      if (!error && response) {
        if (callback) callback()
        dispatch(
          updateDialogueState({
            loading: false,
            load_error: false,
          })
        )
        message.success(
          response.message ? response.message : "Successfully updated dialogue!"
        )
      } else {
        dispatch(updateDialogueState({ loading: false }))
      }
    }
  )
}

export const createDialogue = (data, callback, closemodal) => dispatch => {
  fetchDataAndProceed(
    {
      url: API.createDialogue,
      method: METHOD_TYPES.POST,
      data,
      loader: false,
    },
    (error, response) => {
      if (!error && response?.data) {
        message.success("Successfully created dialogue!")
        if (callback) callback(response.data)
      } else {
        if (closemodal) closemodal()
      }
    }
  )
}

//================================== EDIT DIALOGUE ====================================

export const makeDialogueSocketConnection = host => ({
  type: DL_MAKE_SOCKET_CONNECTION,
  host,
})

export const callDialogueSocketMethod = payload => ({
  type: DL_CALL_SOCKET_METHOD,
  payload,
})

export const dialogueSocketDisconnect = () => ({
  type: DL_SOCKET_DISCONNECT,
})

export const emitDialogueCustomEvent = (event, payload, callback) => ({
  type: DL_EMIT_CUSTOM_EVENT,
  payload,
  event,
  callback,
})

// export const getDialogueDetails =
//   (data, onSuccessCallback, onErrorCallback) => dispatch => {
//     fetchDataAndProceed(
//       {
//         url: API.getDialogueDetails,
//         method: METHOD_TYPES.POST,
//         data,
//         loader: true,
//       },
//       (error, response) => {
//         if (!error && response?.data) {
//           message.success("Dialogue Data Loaded!")
//           if (onSuccessCallback) onSuccessCallback()
//           dispatch(
//             updateDialogueState({
//               messages: response.data?.rawTrackerObj?.prev_msg || [],
//               dialogue_name: response?.data?.rawTrackerObj?.dialogName,
//               selected_message: {
//                 id: response.data?.rawTrackerObj?.prev_msg.at(-1)?._id,
//               },
//             })
//           )
//         } else if (error) {
//           if (onErrorCallback) onErrorCallback()
//         }
//       }
//     )
//   }

export const deleteDialogueMessage = payload => ({
  type: DL_DELETE_MESSAGE,
  payload,
})

export const trainDialogues = data => dispatch => {
  dispatch(updateDialogueState({ dialogues_training: true }))
  fetchDataAndProceed(
    {
      url: API.trainDialogues,
      data,
      loader: false,
    },
    (err, res) => {
      dispatch(updateDialogueState({ dialogues_training: false }))
      if (!err && res) {
        message.success(
          res.message ? res.message : "Successfully trained dialogues!"
        )
      }
    }
  )
}
