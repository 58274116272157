import React from "react"
import axios from "axios"
import { Tooltip, message } from "antd"
import { createBrowserHistory as createHistory } from "history"
import { store } from "../../index"

import { updatePageState } from "../redux/page_details/actions"
import { resetReduxState } from "../redux/admin_details/actions"

import { LOCAL_STORAGE, METHOD_TYPES } from "../constants/common"
import { BASE_URL, BASE_URL_USERS, NETWORK_CHECK_URL } from "../config/urls"
import { BRAND_INFO } from "../config/setup"

import { log, uniqueId } from "./common"

export const clearAllDataFromLocalStorage = () => {
  localStorage.removeItem(LOCAL_STORAGE.ORI_ADMIN)
  localStorage.removeItem(LOCAL_STORAGE.LIVE_CHATS_UNREAD_MESSAGE_COUNT)
  localStorage.removeItem(LOCAL_STORAGE.UNSEEN_LIVE_CHATS_STALL_COUNT)
  localStorage.removeItem(LOCAL_STORAGE.BIFURCATION_DATA)
}

export const getDataFromLocalStorage = (key, undefined_return_value) => {
  const data = localStorage.getItem(key)
  return data && data !== undefined ? JSON.parse(data) : undefined_return_value
}

export const setDataInLocalStorage = (key, data) => {
  const json_data = JSON.stringify(data)
  localStorage.setItem(key, json_data)
}

const getHeaders = content_type => {
  const user = getDataFromLocalStorage(LOCAL_STORAGE.ORI_ADMIN, null)
  let headers = { "Content-Type": content_type }
  if (user && user.token) headers.Authorization = `bearer ${user.token}`
  return headers
}

export const fetchDataAndProceed = (
  {
    url,
    method = METHOD_TYPES.GET,
    data = {},
    timeout = 20000,
    modalLoader = false,
    loader = true,
    loader_text = "loading",
    content_type = "application/json",
    responseType,
    axios_args = {},
  } = {},
  callback
) => {
  const baseURL =
    url.includes("/users") && BASE_URL_USERS ? BASE_URL_USERS : BASE_URL
  if (modalLoader) store.dispatch(updatePageState({ modal_loading: true }))
  else if (loader)
    store.dispatch(
      updatePageState({ page_loading: true, page_loading_text: loader_text })
    )
  const defaultData = {}
  let params = {}
  let payload = {}
  const admin_details = store.getState().admin_details
  if (
    admin_details.bifurcation_data &&
    admin_details.bifurcation_data.selected &&
    admin_details.bifurcation_data.filters &&
    admin_details.bifurcation_data.filters.length > 0
  )
    defaultData.selectedBifurcation = admin_details.bifurcation_data.selected
  if (content_type === "application/json") {
    params =
      method === METHOD_TYPES.GET ? { ...defaultData, ...data } : defaultData
    payload =
      method !== METHOD_TYPES.GET ? { ...defaultData, ...data } : defaultData
  } else {
    params = method === METHOD_TYPES.GET ? data : {}
    payload = method !== METHOD_TYPES.GET ? data : {}
  }
  axios({
    url,
    baseURL,
    method,
    timeout,
    responseType,
    params: params,
    data: payload,
    headers: getHeaders(content_type),
    validateStatus: status => (status >= 200 && status < 300) || status === 412,
    ...axios_args,
  })
    .then(response => {
      log(`${url} [${method}] RESPONSE: `, response.data)
      callback(false, response.data)
      if (modalLoader) store.dispatch(updatePageState({ modal_loading: false }))
      else if (loader)
        store.dispatch(
          updatePageState({ page_loading: false, page_loading_text: "" })
        )
    })
    .catch(error => {
      log(`${url} [${method}] ERROR: `, error.response)
      callback(true)
      if (modalLoader) store.dispatch(updatePageState({ modal_loading: false }))
      else if (loader)
        store.dispatch(
          updatePageState({ page_loading: false, page_loading_text: "" })
        )
      if (
        error &&
        (error.message === "Network Error" || error.code === "ECONNABORTED")
      ) {
        checkInternetConnection()
        if (error.code === "ECONNABORTED") message.warning("Request Timeout")
      }

      if (error && error.response) {
        if (error.response.status === 401) store.dispatch(resetReduxState())
        if (
          error.response.status === 800 &&
          error.response.data &&
          error.response.data.data &&
          error.response.data.data.pathname
        ) {
          const history = createHistory()
          history.push(error.response.data.data.pathname)
          window.location.reload()
        }
        if (error.response.data && error.response.data.message)
          message.error(error.response.data.message)
        else if (error.response.message) message.error(error.response.message)
      }
    })
}

export const fetchWithTimeout = (url, options, timeout = 10000) => {
  return Promise.race([
    fetch(url, options),
    new Promise((_, reject) =>
      setTimeout(() => reject(new Error("timeout")), timeout)
    ),
  ])
}

export const checkInternetConnection = callback => {
  if (navigator.onLine) {
    if (!store.getState().page_details.checking_internet) {
      store.dispatch(updatePageState({ checking_internet: true }))
      fetchWithTimeout(NETWORK_CHECK_URL, {
        mode: "no-cors",
      })
        .then(() => {
          store.dispatch(
            updatePageState({
              checking_internet: false,
              is_internet_connected: true,
            })
          )
        })
        .catch(error => {
          store.dispatch(updatePageState({ checking_internet: false }))
          if (error && error.message === "timeout") {
            store.dispatch(updatePageState({ is_internet_connected: false }))
            if (typeof callback === "function") callback()
          }
        })
    }
  } else store.dispatch(updatePageState({ is_internet_connected: false }))
}

export const getAuthSocketData = () => {
  const admin_details = store.getState().admin_details
  const oriAdminDetails = getDataFromLocalStorage(LOCAL_STORAGE.ORI_ADMIN, null)
  const auth_socket_data = {
    transportOptions: {
      polling: {
        extraHeaders: {
          Authorization:
            oriAdminDetails &&
            oriAdminDetails.token &&
            oriAdminDetails.token.trim().length > 0
              ? oriAdminDetails.token
              : null,
        },
      },
    },
    query: {
      role: BRAND_INFO.data.socket_query.role,
      channelName: BRAND_INFO.data.socket_query.channelName,
      psid:
        oriAdminDetails &&
        oriAdminDetails.admin_id &&
        oriAdminDetails.admin_id.trim().length > 0
          ? oriAdminDetails.admin_id
          : null,
      brandName: BRAND_INFO.data.socket_query.brandName,
      _id:
        oriAdminDetails &&
        oriAdminDetails._id &&
        oriAdminDetails._id.trim().length > 0
          ? oriAdminDetails._id
          : null,
      selectedBifurcation: admin_details.bifurcation_data.selected
        ? admin_details.bifurcation_data.selected
        : null,
    },
  }
  return auth_socket_data
}

export const encrypt = (salt => {
  const textToChars = text => text.split("").map(c => c.charCodeAt(0))
  const byteHex = n => ("0" + Number(n).toString(16)).substr(-2)
  const applySaltToChar = code =>
    textToChars(salt).reduce((a, b) => a ^ b, code)

  return text =>
    text.split("").map(textToChars).map(applySaltToChar).map(byteHex).join("")
})(process.env.REACT_APP_CIPHER_SECRET_KEY)

export const dynamicallyLoadScript = (
  FILE_URL,
  async = true,
  type = "text/javascript"
) => {
  try {
    const scriptEle = document.createElement("script")
    scriptEle.type = type
    scriptEle.async = async
    scriptEle.src = FILE_URL
    document.body.appendChild(scriptEle)
  } catch (error) {
    console.log("dynamicallyLoadScript Error", error)
  }
}

export const addVariationsToMessage = (
  msg_doc,
  { type, label, value },
  defaultValues
) => {
  const message = JSON.parse(JSON.stringify(msg_doc))
  const variations = []
  let defaultVariationAdded = false
  if (message?.variations?.length > 0)
    message.variations.forEach(item => {
      const newVariation = JSON.parse(JSON.stringify(item))
      delete newVariation.abTestings
      newVariation.weight = 100
      newVariation.vid = uniqueId("VARIATION")
      newVariation.variation[type] = { label, value }
      if (
        item.variation[type] &&
        item.variation[type].value !== defaultValues[type].value
      ) {
        defaultVariationAdded = true
        variations.push(item)
      } else {
        item.variation[type] = defaultValues[type]
        variations.push(item)
      }
      const index = variations.findIndex(vitem => {
        let bool = false
        if (
          vitem.variation &&
          JSON.stringify(vitem.variation) ===
            JSON.stringify(newVariation.variation)
        )
          bool = true
        return bool
      })
      if (index === -1) variations.push(newVariation)
    })

  if (!defaultVariationAdded) {
    const newDefaultVariation = JSON.parse(JSON.stringify(message))
    delete newDefaultVariation.abTestings
    delete newDefaultVariation.variations
    delete newDefaultVariation.variationList
    if (variations.length > 0) {
      Object.keys(variations[0].variation).forEach(typeKey => {
        newDefaultVariation.variation = {
          ...newDefaultVariation.variation,
          [typeKey]: defaultValues[typeKey],
        }
      })
    }
    newDefaultVariation.variation = {
      ...newDefaultVariation.variation,
      [type]: { label, value },
    }
    newDefaultVariation.vid = uniqueId("VARIATION")
    newDefaultVariation.weight = 100
    variations.push(newDefaultVariation)
  }

  message.variations = variations
  if (message.variationList)
    message.variationList.push({
      type,
      label,
      value,
    })
  else message.variationList = [{ type, label, value }]
  return message
}

export const renderHtmlStringInTooltip = item => (
  <Tooltip
    placement="top"
    title={item ? <div dangerouslySetInnerHTML={{ __html: item }} /> : ""}
    mouseEnterDelay={0.5}
    destroyTooltipOnHide
  >
    <p
      className="ori-dotted-after-sm-2 ori-block-text-overflow-dotted"
      style={{ minWidth: 150 }}
    >
      {item}
    </p>
  </Tooltip>
)

export const convertOrdinalNumbers = num => {
  let modNum = num % 10
  if (modNum === 1) {
    return num + "st"
  }
  if (modNum === 2) {
    return num + "nd"
  }
  if (modNum === 3) {
    return num + "rd"
  }
  return num + "th"
}