import { MODULE_CONFIG } from "../../config/setup"
import { UPDATE_NLP_MODEL_STATE, MT_LOAD_DEPLOYMENT_LOGS } from "./actiontypes"
import states from "./states"

const nlp_model_details = (state = states.nlp_model_details, action) => {
  switch (action.type) {
    case UPDATE_NLP_MODEL_STATE:
      return {
        ...state,
        ...action.payload,
      }

    case MT_LOAD_DEPLOYMENT_LOGS: {
      return {
        ...state,
        deployment_log: [
          ...state.deployment_log,
          ...action.payload.DeploymentLogs,
        ],
        dl_load_more:
          action.payload.DeploymentLogs.length ===
          MODULE_CONFIG.MODEL_MANAGEMENT.LOAD_MORE_DEPLOYMENT_LOGS,
      }
    }

    default:
      return state
  }
}

export default nlp_model_details
